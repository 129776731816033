export const fontSizes = {
  default: '14px',
  small: '12px',
  xSmall: '11px',
  xxSmall: '10px',
  medium: '16px',
  large: '20px',
  xLarge: '24px',
  xxLarge: '30px',
  // Functional names
  modalTitle: '11px',
  keypadBadge: '10px',
  keypadOptionalText: '10px',
};

export const letterSpacing = {
  modalTitle: '1.6px',
};

export const fontWeight = {
  modalTitle: '500',
  '400': 400,
  '500': 500,
  '700': 700,
};
