import { createTheme } from '@mui/material';

import { Colors } from './colors';

export const themeBaseSpacing = 8;
export const themeZIndexes = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  popper: 6000,
  snackbar: 9001,
  tooltip: 9002,
};

export const borders = {
  separator: `1px solid ${Colors.athens}`,
};

export const defaultFontFamily = "'Rubik', sans-serif";

export const theme = createTheme({
  spacing: themeBaseSpacing,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: Colors.primary,
      light: Colors.titanPurple,
    },
    secondary: {
      main: Colors.storm,
      light: Colors.iron,
    },
    warning: {
      main: Colors.orange,
      light: Colors.dawn,
    },
    error: {
      main: Colors.red,
      light: Colors.strawberry,
    },
    info: {
      main: Colors.blue,
      light: Colors.sky,
    },
    success: {
      main: Colors.green,
      light: Colors.fresco,
    },
    text: {
      primary: Colors.night,
      secondary: Colors.shark,
      disabled: Colors.iron,
    },
    divider: Colors.titanPurple,
  },
  shape: {
    borderRadius: 4,
  },
  zIndex: themeZIndexes,
  typography: {
    fontFamily: defaultFontFamily,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          input: {
            height: '24px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: defaultFontFamily,
          lineHeight: `${themeBaseSpacing * 3}px`,
          backgroundColor: Colors.night,
          padding: `${themeBaseSpacing}px ${themeBaseSpacing * 2}px`,
          fontSize: 14,
          color: Colors.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: 2,
        },
      },
    },
  },
});
