import Konva from 'konva';
import { Coordinates, OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { CircleAreaGlyph } from '../glyphs/CircleAreaGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { adjustStaticCoordinatesByPitchSize, getPlayerReferences, getStaticCoordinates, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class CircleAreaHighlight extends OverlayElementGlyphs {
  circle: CircleAreaGlyph;
  finalSize: number = 0;
  initialSize: number = 0;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  staticCoordinates: Coordinates[];
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.circle = new CircleAreaGlyph({
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
      colorPrimary: lineGlyphConfig.colorPrimary,
    });

    this.initialSize = lineGlyphConfig.initial_size ?? 1;
    this.finalSize = lineGlyphConfig.final_size ?? 1;
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.staticCoordinates = getStaticCoordinates(this.references);
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.circle.shape);
  }

  update({ scale, frame, pitchSize }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    const coordinates = adjustStaticCoordinatesByPitchSize(this.staticCoordinates, pitchSize);
    const circleCenter = coordinates[0];
    const circleEdge = coordinates[1];
    const radius = circleEdge.x - circleCenter.x;

    this.circle.update(radius, coordinates[0], scale);
  }

  removeFromLayer = () => {
    this.circle.shape.destroy();
  };

  show = () => {
    this.circle.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.circle.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
