import { PlayersPosition } from '../../main';
import { Coordinates, PitchSize } from '../../types';
import { Reference } from '../../utils/loaders';
import { Teams } from '../types';
import { OverlayElementGlyphs } from './interface';

export const findPlayersCoordinates = (playerIds: string[], playersPositions: PlayersPosition) => {
  const resultPlayerIds = {} as { [key in string]: Coordinates };

  if (!playersPositions) return resultPlayerIds;

  for (let i = 0; i < playerIds.length; i++) {
    const playerId = playerIds[i];

    const playerCoordinates = playersPositions[playerId];
    if (!playerCoordinates) continue;
    resultPlayerIds[playerId] = playerCoordinates;
  }

  return resultPlayerIds;
};

export const findClosestRivalPlayerDistance = (
  playerId: string,
  playersPositions: PlayersPosition,
  teams: Teams,
): [number, string] => {
  const isPlayerInHomeTeam = teams.homeTeam.playerIds.some((homePlayerId) => homePlayerId === playerId);
  const rivalPlayerIds = isPlayerInHomeTeam ? teams.awayTeam.playerIds : teams.homeTeam.playerIds;

  const playersCoordinates = findPlayersCoordinates([playerId, ...rivalPlayerIds], playersPositions);
  let closestDistance = Infinity;
  let foundPlayerId = '0';

  for (let i = 0; i < rivalPlayerIds.length; i++) {
    const rivalPlayerId = rivalPlayerIds[i];
    if (!playersCoordinates[rivalPlayerId]) continue;
    const y = playersCoordinates[rivalPlayerId].x - playersCoordinates[playerId].x;
    const x = playersCoordinates[rivalPlayerId].y - playersCoordinates[playerId].y;

    const distance = Math.sqrt(x * x + y * y);

    if (distance < closestDistance && distance !== 0) {
      closestDistance = distance;
      foundPlayerId = rivalPlayerId;
    }
  }

  return [closestDistance, foundPlayerId];
};

export const getPlayerReferences = (references: Reference[]) => {
  return references.reduce(
    (acc, reference) => {
      if (reference.referenceType === 'players') {
        return reference.values.map((v) => v);
      }
      return acc;
    },
    <string[]>[],
  );
};

export const getStaticCoordinates = (references: Reference[]) => {
  return references.reduce(
    (acc, reference) => {
      if (reference.referenceType === 'static-coordinates') {
        return reference.values.map((value) => {
          return { x: Number(value[0]), y: Number(value[1]) };
        });
      }
      return acc;
    },
    <Coordinates[]>[],
  );
};

export const adjustStaticCoordinatesByPitchSize = (coordinates: Coordinates[], pitchSize: PitchSize) => {
  return coordinates.map((coordinate) => {
    return {
      x: pitchSize.length / 2 + coordinate.x,
      y: pitchSize.width / 2 + coordinate.y,
    };
  });
};

export const isFrameInRange = (frame: number, overlayElement: OverlayElementGlyphs) => {
  const isInRange = frame >= overlayElement.startFrame && frame <= overlayElement.endFrame;

  !isInRange ? overlayElement.hide() : overlayElement.show();

  return isInRange;
};

function generateFramesArray(start: number, end: number): number[] {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

export function sampleArrayValues(startFrame: number, endFrame: number, sampleSize: number): any[] {
  const listOfFrames = generateFramesArray(startFrame, endFrame);
  const result = [listOfFrames[0]];
  for (let i = 1; i < listOfFrames.length - 1; i += sampleSize) {
    result.push(listOfFrames[i]);
  }
  result.push(listOfFrames[listOfFrames.length - 1]);
  return result;
}
